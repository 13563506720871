// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// jquery
import $ from 'jquery'
global.$ = $
global.jQuery = $
require('jquery-ui')
require("bootstrap")
Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(function(){
    $('.sortable').sortable({
        activate: function (e,i) {
            $(this).addClass("dragging");
        },
        stop: function (e,i){
            $(this).removeClass("dragging");
        },
        update: function (e,i) {
            $.ajax({
                url: $(this).data("url"),
                type: "PATCH",
                data: $(this).sortable("serialize")
            })
        }
    });
});
document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
        $('[data-toggle="collapse"]').collapse()
    })
})
